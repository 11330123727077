'use strict';

import $ from 'jquery'

$(function() {

  var $document = $(document),
      $window = $(window),
      $footer = $('.l-footer');


  /**
   * ## Contentheight (Fallback for old browsers)
   */
  var calcKeyvisualHeight = function() {
    var viewPortHeight = $window.height();
    $('.main-content').css({'min-height': viewPortHeight});
  };

  calcKeyvisualHeight();

  $window.on('resize', calcKeyvisualHeight);



  /**
   * ## Footer Nav
   */
  $document.on('click', '.nav-footer a', function(event) {
    event.preventDefault();


    var $this = $(this);
    var $targetFooterItem = $(this.hash);
    var hash = $this.attr('href');

    $('.nav-footer a').removeClass('current');
    $this.addClass('current');
    $footer.show();

    $('.footer-item').hide();
    $targetFooterItem.fadeIn();

    // hash in URL
    if(history.pushState) {
      history.pushState(null, null, hash);
    }
    else {
      location.hash = hash;
    }

    // Scroll to position
    var position = $('.nav-footer').offset().top;
    var offset = 50;

    $('html,body').animate({scrollTop:position-offset}, 500,'swing');

  });

  // Pageload with #hash
  var getHashAndShowFooterItem = function() {
    var hash = window.location.hash;

    if(hash.length) {
      $('.nav-footer').find('a[href=' + hash + ']').click();
    }
  };

  getHashAndShowFooterItem();



  /**
   * ## Close Footer
   */
  $document.on('click', '.close-footer', function() {

    $footer.slideUp(500, 'swing', function () {
      window.setTimeout(function() {
        $('.footer-item').hide();
        $('.nav-footer a').removeClass('current');
      }, 300);

      if(history.pushState) {
        window.location.hash = '';
        history.pushState('', document.title, window.location.pathname);
      }
      else {
        window.location.hash = '';
      }

    });

  });

});
